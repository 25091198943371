import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import axios from 'axios';
import routeConfig from '../../../routeConfig';
import Loading from '../../Loading';
import { format } from 'date-fns'

export default function ViewCmdb() {   

    return <div className="row">     
        <div className="col">
            Click to Open in New Window:
            <td><a target="_blank" rel="noopener noreferrer" href={`https://app.powerbigov.us/groups/me/apps/c5c9d8a9-6853-4255-880a-cbb5379e58ca/reports/3d2ee6ce-9425-478d-8741-640030a7f94d/ReportSection74ba0d5d43363c61bc56?ctid=db21de5d-bc9c-420c-8f3f-8f08f85b5ada`}
                title="Open Report in new Tab">Cherwell- Power BI CMDB Report Dashboard</a></td>      
        </div>
    </div>

  
}