import React from 'react';
import { useSelector, useStore, useDispatch } from "react-redux";
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';
import SaveCancel from '../../SaveCancel';
import Card from '../../Card';
import { toastr } from 'react-redux-toastr';

export default function EditPalm() {
    const dispatch = useDispatch();
    const store = useStore();
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state));
    const {
        state,
        setStateProperty,
    } = useNestedArrayState(currentApplication);

    const cancel = () => {
        dispatch.Applications.changeApplicationModalIsEditing(false);
    }

    const isStatusDisabled = () => {
        return !state || !state.usesFlairDataOrInterface || !state.usesCBFPIRAFData ||
            !state.usesChartOfAccountData || !state.usesKeyDepartmentFinancialSystems || !state.usesImpactedFocusDatabaseFiles
    }

    const save = (e) => {
        const saveState = { ...state };

        if (saveState.palmStatus === 'Complete' && (!saveState.usesFlairDataOrInterface || !saveState.usesCBFPIRAFData ||
            !saveState.usesChartOfAccountData || !saveState.usesKeyDepartmentFinancialSystems || !saveState.usesImpactedFocusDatabaseFiles)) {
            toastr.error('To set PALM assesment to complete all questions must be answered');
            return;
        }

        if (saveState.palmStatus === 'Complete') {
            if (saveState.usesFlairDataOrInterface === 'Yes'
                || saveState.usesCBFPIRAFData === 'Yes'
                || saveState.usesChartOfAccountData === 'Yes'
                || saveState.usesKeyDepartmentFinancialSystems === 'Yes'

                || saveState.palmUseFinproj === true 
                || saveState.palmUseContractNum === true 
                || saveState.palmUseOrgLevelCode === true 
                || saveState.palmUseOrgCode === true 
                || saveState.palmUseGAAFERFundCode === true 
                || saveState.palmUseStateFund === true 
                || saveState.palmUseFindId === true 
                || saveState.palmUseGeneralLedgeCode === true 
                || saveState.palmUseStateProgram === true 
                || saveState.palmUseObjectCode === true 
                || saveState.palmOtherCoseAccumulator === true 
                || saveState.palmUseVendorCode === true 
                || saveState.palmUseExpansionOption === true 
                
                || saveState.usesImpactedFocusDatabaseFiles === 'Yes') {
                saveState.palmIsImpacted = 'Yes';
            } else {
                saveState.palmIsImpacted = 'No';
                saveState.palmImpactWave = 'NA';
            }
        }

        if (saveState.palmStatus === 'Not Started' && (saveState.usesFlairDataOrInterface || saveState.usesCBFPIRAFData ||
            saveState.usesChartOfAccountData || saveState.usesKeyDepartmentFinancialSystems || saveState.usesImpactedFocusDatabaseFiles)) {
            saveState.palmStatus = 'In Progress';
        }

        dispatch.Applications.persistApplication(saveState);
        dispatch.Applications.changeApplicationModalIsEditing(false);
    }

    return <div>
        <ul className="list-group">
            <li className="list-group-item">
                <div className="row my-1">
                    <div className="col font-weight-bold">1. Does this IT Asset use FLAIR Data or Interface with FLAIR:</div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <select className="form-control-sm form-control" value={state.usesFlairDataOrInterface}
                            onChange={e => setStateProperty('usesFlairDataOrInterface', e)}>
                            <option>Select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div>
            </li>
            <li className="list-group-item">
                <div className="row my-1">
                    <div className="col font-weight-bold">2. Does this IT Asset use Contract, Budget, Funding, Payroll, Property, Invoicing, Receipts, Accounting, and/or Financial data:</div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <select className="form-control-sm form-control" value={state.usesCBFPIRAFData}
                            onChange={e => setStateProperty('usesCBFPIRAFData', e)}>
                            <option>Select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div>
                {state.usesCBFPIRAFData === 'Yes' ?
                    <>
                        <div className="row">
                            <div className="col-2 font-weight-bold">Project Use:</div>
                            <div className="col">
                                <input className="form-check-input" id="projectImpact" type="checkbox" checked={state.wpiiHasProjectImpact}
                                    onChange={e => setStateProperty('wpiiHasProjectImpact', e)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 font-weight-bold">Contract Use:</div>
                            <div className="col">
                                <input className="form-check-input" id="wpiiHasContractImpact" type="checkbox"
                                    checked={state.wpiiHasContractImpact} onChange={e => setStateProperty('wpiiHasContractImpact', e)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 font-weight-bold">Budget Use:</div>
                            <div className="col">
                                <input className="form-check-input" id="wpiiHasBudgetImpact" type="checkbox"
                                    checked={state.wpiiHasBudgetImpact} onChange={e => setStateProperty('wpiiHasBudgetImpact', e)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-2 font-weight-bold">Payroll Use:</div>
                            <div className="col">
                                <input className="form-check-input" id="payrollImpact" type="checkbox" checked={state.hasPayrollImpact}
                                    onChange={e => setStateProperty('hasPayrollImpact', e)} />
                            </div>
                        </div>
                    </>
                    : <></>}
            </li>
            <li className="list-group-item">
                <div className="row my-1">
                    <div className="col font-weight-bold">3. Does this IT Asset use Chart of Account (COA) data:</div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <select className="form-control-sm form-control" value={state.usesChartOfAccountData}
                            onChange={e => setStateProperty('usesChartOfAccountData', e)}>
                            <option>Select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div>
                <br />
                <Card headerText="COA Details" collapsed={true}>
                    <div className="row">
                        <div className="col">
                            <table className="table table-sm table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td>1.	Operating Level Organization (OLO) (6) </td>
                                        <td>2.	Organization Code (11) May sometimes be listed as (9) length since the first two characters are always “55” for FDOT</td>
                                    </tr>
                                    <tr>
                                        <td>3.	Cost Center (3)</td>
                                        <td>4.	GAAFR Fund (2) </td>
                                    </tr>
                                    <tr>
                                        <td>5.	State Fund (1) </td>
                                        <td>6.	Fund Identifier (FID) (6) </td>
                                    </tr>
                                    <tr>
                                        <td>7.	General Ledger Code (GLC) (5) </td>
                                        <td>8.	Object Code (OBJ) (6) </td>
                                    </tr>
                                    <tr>
                                        <td>9.	State Program (10) </td>
                                        <td>10.	Budget Entity (BE) (8) </td>
                                    </tr>
                                    <tr>
                                        <td>11.	Internal Budget Indicator (IBI) (2) </td>
                                        <td>12.	Category (6) </td>
                                    </tr>
                                    <tr>
                                        <td>13.	Year (2) </td>
                                        <td>14.	Project Identifier (11) </td>
                                    </tr>
                                    <tr>
                                        <td>15.	Grant (5) </td>
                                        <td>16.	Contract (5) </td>
                                    </tr>
                                    <tr>
                                        <td>17.	Other Cost Accumulator (OCA) (5) </td>
                                        <td>18.	Combined/Calculated Fields like Account Code (“55” + GAAFR Fund + State Fund + Fund ID + Budget Entity + Internal Budget Indicator + Category + Year)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card>
            </li>
            <li className="list-group-item">
                <div className="row my-1">
                    <div className="col font-weight-bold">4. Does this IT Asset use or integrate data from the following key Department Financial Systems:</div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <select className="form-control-sm form-control" value={state.usesKeyDepartmentFinancialSystems}
                            onChange={e => setStateProperty('usesKeyDepartmentFinancialSystems', e)}>
                            <option>Select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div>
                <br />
                <Card headerText="Department Financial Systems Details" collapsed={true}>
                    <div className="row">
                        <div className="col">
                            <table className="table table-sm table-striped tabled-bordered">
                                <tbody>
                                    <tr>
                                        <td>1.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f832f8e645001c8e3705`}
                                            title="Open Asset in new Tab">Contract Funds Management system (CFM)</a> </td>
                                        <td>2.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f840f8e645001c8e4187`}
                                            title="Open Asset in new Tab">Final Voucher System (FVS/RFV)</a></td>
                                    </tr>
                                    <tr>
                                        <td>3.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f8e8f8e645001c8e851f`}
                                            title="Open Asset in new Tab">Comptroller Automation Platform (CAP)</a></td>
                                        <td>4.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f855f8e645001c8e500a`}
                                            title="Open Asset in new Tab">Receipt Processing System (RPS)</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f82df8e645001c8e335a`}
                                            title="Open Asset in new Tab">Electronic Estimated Disbursements (EED)</a>
                                        </td>
                                        <td>6.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f82cf8e645001c8e3267`}
                                            title="Open Asset in new Tab">Cash Forecasting System (CFS)</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f82df8e645001c8e3302`}
                                            title="Open Asset in new Tab">Consultant Invoice Transmittal System (CITS)</a>
                                        </td>
                                        <td>8.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f832f8e645001c8e36da`}
                                            title="Open Asset in new Tab">Comptroller Cash Reconciliation (CCR)</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f8eaf8e645001c8e8660`}
                                            title="Open Asset in new Tab">Property Management Reporting System (PMRS)</a>
                                        </td>
                                        <td>10.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f82bf8e645001c8e31f1`}
                                            title="Open Asset in new Tab">Accounts Receivable Invoicing (ARI)</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f82cf8e645001c8e3275`}
                                            title="Open Asset in new Tab">Batch Error Management (BEM)</a>
                                        </td>
                                        <td>12.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f83df8e645001c8e3f29`}
                                            title="Open Asset in new Tab">FLAIR Information Delivery Option (FIDO)</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>13.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f893f8e645001c8e6486`}
                                            title="Open Asset in new Tab">MADDOG Report</a>
                                        </td>
                                        <td>14.	FLAIR/FM Detail Report
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>15.	FLAIR Detail Report
                                        </td>
                                        <td>16.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/5d07f843f8e645001c8e43fd`}
                                            title="Open Asset in new Tab">Project Cost Management (PCM)</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>17.	<a target="_blank" rel="noopener noreferrer" href={`/Inventory/d07f82ef8e645001c8e341c`}
                                            title="Open Asset in new Tab">Work Program Administration (WPA)</a>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card>
            </li>
            <li className="list-group-item">
                <div className="row my-1">
                    <div className="col font-weight-bold">5. Does this IT Asset use or integrate with the following databases, tables, flat files, or FOCUS Masters:</div>
                </div>

                <div className="row">
                    <div className="col">
                        <select className="form-control-sm form-control" value={state.usesImpactedFocusDatabaseFiles}
                            onChange={e => setStateProperty('usesImpactedFocusDatabaseFiles', e)}>
                            <option>Select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div>
                <br />
                <Card headerText="Table Details" collapsed={true}>
                    <div className="row">
                        <div className="col">
                            <table className="table table-sm table-striped table-bordered">
                                <tbody>
                                    <tr>
                                        <td colSpan="4">
                                            Any data used from these system tables is PALM Impacted:
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1.	ACR*.*</td>
                                        <td>2.	BGT*.*</td>
                                        <td>3.	CFM*.*</td>
                                        <td>4.	CCR*.*</td>
                                    </tr>
                                    <tr>
                                        <td>5.	CFS*.*</td>
                                        <td>6.	CIT*.*</td>
                                        <td>7.	CST*.*</td>
                                        <td>8.	DAS*.*</td>
                                    </tr>
                                    <tr>
                                        <td>9.	EED*.*</td>
                                        <td>10.	IEI*.*</td>
                                        <td>11.	LFA*.*</td>
                                        <td>12.	SAM*.*   </td>
                                    </tr>
                                    <tr>
                                        <td>13.	OOC*.*</td>
                                        <td>14.	PCD*.*</td>
                                        <td>15.	RPS*.*</td>
                                        <td>16.	FDA*.*</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Any data used from these system tables may be PALM Impacted: (narrow the scope of system data just to financial data use ONLY):
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1.	ARA*.*</td>
                                        <td>2.	CEL*.*</td>
                                        <td>3.	CRS*.*</td>
                                        <td>4.	CONT*.*</td>
                                    </tr>
                                    <tr>
                                        <td>5.	CAST*.*</td>
                                        <td>6.	DCPTBL20, 30, 31</td>
                                        <td>7.	DSA*.* </td>
                                        <td>8.	EOC*.*</td>
                                    </tr>
                                    <tr>
                                        <td>9.	EXP*.*</td>
                                        <td>10.	FAMS*.*</td>
                                        <td>11.	FTS*.*</td>
                                        <td>12.	MEQTBL10</td>

                                    </tr>
                                    <tr>
                                        <td>13.	MSI*.*</td>
                                        <td>14.	OUT*.*</td>
                                        <td>15.	OWP*.*</td>
                                        <td>16.	LAP*.*</td>

                                    </tr>
                                    <tr>
                                        <td>17.	PPS*.*</td>
                                        <td>18.	PSJ*.*</td>
                                        <td>19.	PSI*.*</td>
                                        <td>20.	RWM*.*</td>

                                    </tr>
                                    <tr>
                                        <td>21.	RCI*.*</td>
                                        <td>22.	TVI*.*</td>
                                        <td>23.	TRK*.*</td>
                                        <td>24.	SNPDB2* Databases</td>

                                    </tr>
                                    <tr>
                                        <td>25.	WPG*.*</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </Card>
            </li>
            <li className="list-group-item">
                <div className="row my-1">
                    <div className="col font-weight-bold">6. First PALM Impact Wave</div>
                </div>
                <div className="row">
                    <div className="col">
                        <select className="form-control-sm form-control" value={state.palmImpactWave}
                            onChange={e => setStateProperty('palmImpactWave', e)}>
                            <option>Select</option>
                            <option value="Central">Central</option>
                            <option value="Departmental">Departmental</option>
                            <option value="Payroll">Payroll</option>
                            <option value="NA">NA</option>
                        </select>
                    </div>
                </div>
            </li>
          

            <li className="list-group-item">
                <div className="row my-1">
                    <div className="col-10 font-weight-bold">7. Does this IT asset use any of the following PALM impacted fields?</div> <div class="text-warning"> Check all that Apply</div>
                </div>
                {/*<div className="row">*/}
                {/*    <div className="col-4">*/}
                {/*        <select className="form-control-sm form-control" value={state.palmUseFLAIRData}*/}
                {/*            onChange={e => setStateProperty('palmUseFLAIRData', e)}>*/}
                {/*            <option>Select</option>*/}
                {/*            <option value="No">No</option>*/}
                {/*            <option value="Yes">Yes</option>*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* {state.palmIsImpacted === 'Yes' ?*/}
                <>
                    <div className="row">
                        <div className="col-8 font-weight-bold">Project Identifier (Financial Project) [e.g. FINPROJ 431684-1-52-01, 43168415201]:</div>
                        <div className="col">
                            <div className="col">
                                <input className="form-check-input" id="palmUseFinproj" type="checkbox" checked={state.palmUseFinproj}
                                    onChange={e => setStateProperty('palmUseFinproj', e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">Contract [e.g. E4Q15, T9401]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseContractNum" type="checkbox"
                                checked={state.palmUseContractNum} onChange={e => setStateProperty('palmUseContractNum', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">Operating Level Organization [e.g. 550000]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseOrgLevelCode" type="checkbox" checked={state.palmUseOrgLevelCode}
                                onChange={e => setStateProperty('palmUseOrgLevelCode', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">Organization Code [e.g. 551600973]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseOrgCode" type="checkbox" checked={state.palmUseOrgCode}
                                onChange={e => setStateProperty('palmUseOrgCode', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">GAAFR Fund [e.g. 10, 20, 30]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseGAAFERFundCode" type="checkbox" checked={state.palmUseGAAFERFundCode}
                                onChange={e => setStateProperty('palmUseGAAFERFundCode', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">State Fund [e.g. 1, 2, 3, 4, 5, 6, 7, 8, 9]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseStateFund" type="checkbox" checked={state.palmUseStateFund}
                                onChange={e => setStateProperty('palmUseStateFund', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">Fund ID [e.g. 540001]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseFindId" type="checkbox" checked={state.palmUseFindId}
                                onChange={e => setStateProperty('palmUseFindId', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">General Ledger Code[e.g. 94100]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseGeneralLedgeCode" type="checkbox" checked={state.palmUseGeneralLedgeCode}
                                onChange={e => setStateProperty('palmUseGeneralLedgeCode', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">State Program[e.g. 1603000000000000]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseStateProgram" type="checkbox" checked={state.palmUseStateProgram}
                                onChange={e => setStateProperty('palmUseStateProgram', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">Object Code [e.g.132700, 891108]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseObjectCode" type="checkbox" checked={state.palmUseObjectCode}
                                onChange={e => setStateProperty('palmUseObjectCode', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">Other Cost Accumulator [e.g. ID059, ID048]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmOtherCoseAccumulator" type="checkbox" checked={state.palmOtherCoseAccumulator}
                                onChange={e => setStateProperty('palmOtherCoseAccumulator', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">Vendor Number [e.g. F123456789001 C123456789001 N123456789001]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseVendorCode" type="checkbox" checked={state.palmUseVendorCode}
                                onChange={e => setStateProperty('palmUseVendorCode', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 font-weight-bold">Expansion Option [A1, AA, ZA]:</div>
                        <div className="col">
                            <input className="form-check-input" id="palmUseExpansionOption" type="checkbox" checked={state.palmUseExpansionOption}
                                onChange={e => setStateProperty('palmUseExpansionOption', e)} />
                        </div>
                    </div>

                </> 
                <>
                </>
            </li>

            <li className="list-group-item">
                <div className="row">
                    <div className="col-2 font-weight-bold">
                        Assessment Status:
                    </div>
                    <div className="col-4">
                        <select className="form-control-sm form-control" value={state.palmStatus} disabled={isStatusDisabled()}
                            onChange={e => setStateProperty('palmStatus', e)}>
                            <option value="In Progress">In Progress</option>
                            <option value="Complete">Complete</option>
                        </select>
                    </div>
                </div>
            </li>

        </ul>
        <div className="row">
            <div className="col"></div>
        </div>
        <SaveCancel onSaveClick={save} onCancelClick={cancel}></SaveCancel>
    </div>
}